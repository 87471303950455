<template>
  <div>
    <v-toolbar
      flat
      dark
      dense
      class="viewToolbar blue accent-2"
      :class="{ 'with-left-button': doShowBackButton || doShowCloseButton }"
    >
      <v-btn
        v-if="doShowBackButton"
        color="white"
        small
        text
        class="pr-0 pl-0 back-button"
        @click="onBackButton()"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn
        v-else-if="doShowCloseButton"
        color="white"
        small
        text
        class="pr-0 pl-0 back-button"
        @click="closePopup()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-select
        v-if="titleItems"
        outlined
        dense
        @change="onTitleItem"
        :items="titleItems"
        style="position:relative; top:13px"
        :value="titleValue"
      ></v-select>
      <v-toolbar-title v-else>
        {{ title }}
      </v-toolbar-title>
      <!-- <v-toolbar-title class="text-button">
        <div class="text-button">{{ title }}</div>
      </v-toolbar-title> -->
      <v-spacer></v-spacer>
      <template>
        <template v-for="button of buttons">
          <v-menu
            v-if="button.type == 'menu'"
            offset-y
            left
            :key="`menu-${button.label}`"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                color="white"
                dark
                small
                text
                class="ml-2"
                :disabled="button.disabled"
              >
                <v-icon :left="!!button.name">mdi-{{ button.icon }}</v-icon>
                <template v-if="!!button.name">
                  {{ button.name }}
                </template>
              </v-btn>
            </template>
            <v-list>
              <template
                v-for="(item, i) in button.menuItems"
              >
                <v-list-item
                  v-if="item.type === 'button'"
                  :key="`button-${i}`"
                  @click="onButtonMenuItem(item)"
                >
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-icon v-if="item.icon">
                    <v-icon>mdi-{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-subheader v-else :key="`subheader-${i}`">{{ item.name.toUpperCase() }}</v-subheader>
              </template>
            </v-list>
          </v-menu>
          <v-tooltip
            v-else
            left
            dark
            :key="`button-${button.label}`"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="button.title ? on : null"
                :color="button.color || 'white'"
                dark
                small
                :text="!button.notText"
                class="ml-2"
                :disabled="button.disabled"
                @click="onButton(button)"
              >
                <v-icon :left="!!button.name">mdi-{{ button.icon }}</v-icon>
                <template v-if="!!button.name">
                  {{ button.name }}
                </template>
              </v-btn>
            </template>
            <span>{{ button.title }}</span>
          </v-tooltip>
        </template>
        <v-menu
          v-if="dropdownMenuItems"
          offset-y
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <template
              v-for="(item, i) in dropdownMenuItems"
            >
              <v-list-item
                v-if="item.type === 'button'"
                :key="`button-${i}`"
                @click="onDropdownMenuItem(item)"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-icon v-if="item.icon">
                  <v-icon>mdi-{{ item.icon }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-subheader v-else :key="`subheader-${i}`">{{ item.name.toUpperCase() }}</v-subheader>
            </template>
          </v-list>
        </v-menu>
      </template>
    </v-toolbar>
    <v-tabs
      v-model="tab" v-if="tabsToUse.length"
    >
      <v-tab
        v-for="tab in tabsToUse"
        :key="tab.label"
      >
        {{ tab.text }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import useCore from '../helpers/core'

export default {
  name: 'ViewToolbar',
  props: {
    title: {
      type: String,
      default: ''
    },
    backRoute: {
      type: String,
      required: false
    },
    showBackButton: {
      type: Boolean,
      default: false
    },
    tabs: {
      type: Array,
      default: null,
    },
    buttons: {
      type: Array,
      required: false
    },
    dropdownMenuItems: {
      type: Array,
      required: false
    },
    titleItems: {
      type: Array,
      required: false
    },
    titleValue: {
      type: String,
      required: false
    },
    emitOnBackButton: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, context) {
    // Vue 2
    const store = require('@/store/store').default;
    const router = require('@/router/router').default;

    const { tellKobra } = useCore();

    const hasTabs = computed(() => {
      return props.tabs;
    });

    const doShowBackButton = computed(() => props.showBackButton && !isPopupLayoutMode.value);
    const doShowCloseButton = computed(() => isPopupLayoutMode.value);

    const isPopupLayoutMode = computed(() => store.getters.isPopupLayoutMode);
    // const isFullLayoutMode = computed(() => store.getters.isFullLayoutMode);

    const tab = ref(hasTabs.value ? props.tabs[0] : null);

    watch(() => tab.value, newValue => {
      // console.log('ac props.doFocus', newValue);
      context.emit('onTab', newValue);
    });

    const tabsToUse = computed(() => {
      //we cannont mutate the prop tabs, so we do this
      let items = [];
      if (props.tabs) {
        for (let item of props.tabs) {
          items.push(item);
        }
      }
      return items;
    });

    const onBackButton = () => {
      if (props.emitOnBackButton) {
        context.emit('onBackButton');
      } else if (props.backRoute) {
        // console.log("onBackButton backRoute", this.backRoute);
        router.replace({ name: props.backRoute });
      } else {
        // console.log("onBackButton history back");
        router.back();
      }
    }

    const onButton = button => {
      context.emit('onButton', button);
    }

    const onTitleItem = item => {
      context.emit('onTitleItem', item);
    }

    const onButtonMenuItem = item => {
      context.emit('onButtonMenuItem', item);
    }
    
    const onDropdownMenuItem = item => {
      context.emit('onDropdownMenuItem', item);
    }

    const closePopup = () => {
      tellKobra('close');
    }

    return {
      onBackButton,
      onButton,
      hasTabs,
      tabsToUse,
      tab,
      doShowBackButton,
      doShowCloseButton,
      closePopup,
      onDropdownMenuItem,
      onTitleItem,
      onButtonMenuItem,
    }

  }
}
</script>

<style>
header.viewToolbar div.v-toolbar__content {
  padding-right: 0 !important;
}
header.with-left-button div.v-toolbar__content {
  padding-left: 0 !important;
}
.back-button {
  left: -4px;
}
</style>