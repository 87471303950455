var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"viewToolbar blue accent-2",class:{ 'with-left-button': _vm.doShowBackButton || _vm.doShowCloseButton },attrs:{"flat":"","dark":"","dense":""}},[(_vm.doShowBackButton)?_c('v-btn',{staticClass:"pr-0 pl-0 back-button",attrs:{"color":"white","small":"","text":""},on:{"click":function($event){return _vm.onBackButton()}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1):(_vm.doShowCloseButton)?_c('v-btn',{staticClass:"pr-0 pl-0 back-button",attrs:{"color":"white","small":"","text":""},on:{"click":function($event){return _vm.closePopup()}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),(_vm.titleItems)?_c('v-select',{staticStyle:{"position":"relative","top":"13px"},attrs:{"outlined":"","dense":"","items":_vm.titleItems,"value":_vm.titleValue},on:{"change":_vm.onTitleItem}}):_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-spacer'),[_vm._l((_vm.buttons),function(button){return [(button.type == 'menu')?_c('v-menu',{key:("menu-" + (button.label)),attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"white","dark":"","small":"","text":"","disabled":button.disabled}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":!!button.name}},[_vm._v("mdi-"+_vm._s(button.icon))]),(!!button.name)?[_vm._v(" "+_vm._s(button.name)+" ")]:_vm._e()],2)]}}],null,true)},[_c('v-list',[_vm._l((button.menuItems),function(item,i){return [(item.type === 'button')?_c('v-list-item',{key:("button-" + i),on:{"click":function($event){return _vm.onButtonMenuItem(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),(item.icon)?_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-"+_vm._s(item.icon))])],1):_vm._e()],1):_c('v-subheader',{key:("subheader-" + i)},[_vm._v(_vm._s(item.name.toUpperCase()))])]})],2)],1):_c('v-tooltip',{key:("button-" + (button.label)),attrs:{"left":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"color":button.color || 'white',"dark":"","small":"","text":!button.notText,"disabled":button.disabled},on:{"click":function($event){return _vm.onButton(button)}}},button.title ? on : null),[_c('v-icon',{attrs:{"left":!!button.name}},[_vm._v("mdi-"+_vm._s(button.icon))]),(!!button.name)?[_vm._v(" "+_vm._s(button.name)+" ")]:_vm._e()],2)]}}],null,true)},[_c('span',[_vm._v(_vm._s(button.title))])])]}),(_vm.dropdownMenuItems)?_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1459812380)},[_c('v-list',[_vm._l((_vm.dropdownMenuItems),function(item,i){return [(item.type === 'button')?_c('v-list-item',{key:("button-" + i),on:{"click":function($event){return _vm.onDropdownMenuItem(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))]),(item.icon)?_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-"+_vm._s(item.icon))])],1):_vm._e()],1):_c('v-subheader',{key:("subheader-" + i)},[_vm._v(_vm._s(item.name.toUpperCase()))])]})],2)],1):_vm._e()]],2),(_vm.tabsToUse.length)?_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabsToUse),function(tab){return _c('v-tab',{key:tab.label},[_vm._v(" "+_vm._s(tab.text)+" ")])}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }